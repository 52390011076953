import React, { useState } from "react"
import styled from "styled-components"
import Arrow from "./icons/arrow.svg"
import Check from "./icons/check.svg"

const Wrapper = styled.div`
  display: flex;
`
const Box = styled.div`
  width: 56px;
  height: 48px;
  background: #6361ff;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  ${props =>
    props.show &&
    `
   background: #dadcfa;
  path {
    stroke: #6361ff;
  }
  `}
`
const OptionHolder = styled.div`
  width: 100px;
  display: flex;
  flex-direction: column;
  border: 1px solid #dcdcdc;
  position: absolute;
  border-radius: 8px;
  overflow: hidden;
  bottom: 208px;
  @media (max-width: 440px) {
    bottom: 224px;
    right: 10%;
  }
`

const Option = styled.div`
  border-bottom: 1px solid #dcdcdc;
  :last-child {
    border-bottom: 1px solid rgba(0, 0, 0, 0);
  }
  width: 100%;
  height: 48px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: white;
  cursor: pointer;
  font-weight: 500;
  color: #425466;
  padding-left: 8px;
  :hover {
    background: #6361ff;
    color: white;
  }
  ${props => props.picked && `background: #6361ff; color: white;`}
`

function ExtensionDropdown({ extension, setExtension }) {
  const Extensions = [
    { name: "xlsx" },
    { name: "xml" },
    { name: "csv" },
    { name: "xlsm" },
    { name: "odc" },
  ]

  const [show, setShow] = useState(false)

  return (
    <Wrapper>
      <Box onClick={() => setShow(!show)} show={show}>
        {" "}
        <Arrow />
      </Box>
      {show && (
        <OptionHolder>
          {Extensions.map((item, index) => {
            return (
              <Option
                key={index}
                picked={item.name == extension}
                onClick={() => setExtension(item.name)}
              >
                <Check /> .{item.name}
              </Option>
            )
          })}
        </OptionHolder>
      )}
    </Wrapper>
  )
}

export default ExtensionDropdown
